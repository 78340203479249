<template>
  <div id="madrid">
    <section
      class="page-header"
      style="background-image: url('/imgs/madrid-01.png')"
    >
      <div class="caption">
        <i class="isologo"></i>
        <h1>{{ $lang("header.title") }}</h1>
      </div>
    </section>

    <section class="section-9">
      <div class="container py-6">
        <div class="row">
          <div class="col-lg-6 pr-lg-5 pb-5 pb-lg-0">
            <i class="isologo isologo-tertiary mb-3"></i>
            <h4 class="text-dark mb-4">{{ $lang("campus.title") }}</h4>

            <div v-html="$lang('campus.desc')" />

            <button
              class="btn btn-tertiary px-5 mt-5 mx-auto ml-md-0"
              @click="$router.push('/contact-us')"
            >
              {{ $lang("campus.button") }}
            </button>
          </div>
          <div class="col-lg-6">
            <swiper
              class="swiper-gallery"
              :modules="modulesWAutoP"
              :pagination="{ clickable: false }"
              :slides-per-view="1"
              :autoplay="true"
              :loop="true"
            >
              <swiper-slide v-for="a of Array.from(Array(6).keys())" :key="a">
                <div
                  class="img-container img-container-rounded minh-7"
                  :style="`background-image: url('/imgs/madrid/0${a + 1}.jpg')`"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="section-10 bg-dark-tint decoration-3">
      <div class="container py-5">
        <swiper
          class="swiper-primary"
          :modules="modules"
          :pagination="{ clickable: false }"
          :breakpoints="swiperBreakpoints"
        >
          <swiper-slide>
            <div class="h-100 bg-tertiary text-white b-radius-3 px-3 py-4 m-2">
              <div class="d-flex justify-content-center align-items-center">
                <div
                  class="img-container icon mr-3"
                  style="background-image: url('/svgs/building_icon_dark.svg')"
                />
                <div class="caption">
                  <span v-html="$lang('campus.features.1')" />
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="h-100 bg-tertiary text-white b-radius-3 px-3 py-4 m-2">
              <div class="d-flex justify-content-center align-items-center">
                <div
                  class="img-container icon mr-3"
                  style="background-image: url('/svgs/person_icon.svg')"
                />
                <div class="caption">
                  <span v-html="$lang('campus.features.2')" />
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="h-100 bg-tertiary text-white b-radius-3 px-3 py-4 m-2">
              <div class="d-flex justify-content-center align-items-center">
                <div
                  class="img-container icon mr-3"
                  style="background-image: url('/svgs/cup_icon.svg')"
                />
                <div class="caption">
                  <span v-html="$lang('campus.features.3')" />
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="section-11 bg-tertiary text-white py-5 decoration-3">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg">
            <div
              class="img-container img-container-circle"
              style="background-image: url('/imgs/madrid.png')"
            >
              <i class="isologo"></i>
            </div>
          </div>
          <div class="col-lg">
            <i class="isologo isologo-white mb-3"></i>
            <h4 class="mb-4 text-dark">{{ $lang("why_madrid.title") }}</h4>

            <div v-html="$lang('why_madrid.desc')" />

            <button
              class="btn btn-dark px-5 mt-5"
              @click="$router.push('/contact-us')"
            >
              {{ $lang("why_madrid.button") }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="section-12 py-5 text-center">
      <div class="container py-5">
        <i class="isologo isologo-tertiary mb-3"></i>
        <h4 class="mb-5 text-dark">{{ $lang("team.title") }}</h4>
        <p class="text-medium">{{ $lang("team.subtitle") }}</p>

        <swiper
          :modules="modulesWAutoP"
          :pagination="{ clickable: true }"
          class="mt-5 swiper-medium"
          :breakpoints="swiperBpMembers"
          :slides-per-view="1"
          :autoplay="true"
          :loop="true"
        >
          <swiper-slide>
            <div
              class="img-container team-member"
              style="background-image: url('/imgs/teams/madrid_1.png')"
            />
            <p class="mb-0">
              <b class="text-dark">{{ $lang("team.people.1.name") }}</b>
              <br />
              <small>{{ $lang("team.people.1.nationality") }}</small>
            </p>
          </swiper-slide>
          <swiper-slide>
            <div
              class="img-container team-member"
              style="background-image: url('/imgs/teams/madrid_2.png')"
            />
            <p class="mb-0">
              <b class="text-dark">{{ $lang("team.people.2.name") }}</b>
              <br />
              <small>{{ $lang("team.people.2.nationality") }}</small>
            </p>
          </swiper-slide>
          <swiper-slide>
            <div
              class="img-container team-member"
              style="background-image: url('/imgs/teams/madrid_3.png')"
            />
            <p class="mb-0">
              <b class="text-dark">{{ $lang("team.people.3.name") }}</b>
              <br />
              <small>{{ $lang("team.people.3.nationality") }}</small>
            </p>
          </swiper-slide>
          <swiper-slide>
            <div
              class="img-container team-member"
              style="background-image: url('/imgs/teams/madrid_4.png')"
            />
            <p class="mb-0">
              <b class="text-dark">{{ $lang("team.people.4.name") }}</b>
              <br />
              <small>{{ $lang("team.people.4.nationality") }}</small>
            </p>
          </swiper-slide>
          <swiper-slide>
            <div 
              class="img-container team-member" 
              style="background-image: url('/imgs/teams/madrid_5.png')" 
            />
            <p class="mb-0">
              <b class="text-dark">{{ $lang("team.people.5.name") }}</b>
              <br />
              <small>{{ $lang("team.people.5.nationality") }}</small>
            </p>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="section-13 bg-dark text-white py-6 decoration-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <i class="isologo isologo-tertiary mb-2"></i>
            <h4 class="mb-5">{{ $lang("methodology.title") }}</h4>
          </div>
          <div class="col-lg">
            <h3 class="text-tertiary mb-5">
              {{ $lang("methodology.subtitle") }}
            </h3>
            <div v-html="$lang('methodology.desc')"></div>
          </div>
          <div class="col-lg">
            <div
              class="img-container img-container-rounded h-100"
              style="background-image: url('/imgs/barcelona_2.png')"
            />
          </div>
          <div class="col-12">
            <button
              class="btn btn-tertiary px-5 mx-auto mt-5"
              @click="$router.push('/contact-us')"
            >
              {{ $lang("methodology.button") }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <LevelsSections>
      <button
        class="btn btn-tertiary text-white mx-auto"
        @click="$router.push('/contact-us')"
      >
        {{ $lang("levels.button") }}
      </button>
    </LevelsSections>
  </div>
</template>

<script>
import LevelsSections from "@/components/LevelsSections";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    LevelsSections,
  },
  data: () => ({
    modulesWAutoP: [Pagination, Navigation, Autoplay],
    modules: [Pagination, Navigation],
    swiperBreakpoints: {
      0: { slidesPerView: 1 },
      768: { slidesPerView: 3 },
    },
    swiperBpMembers: {
      0: { slidesPerView: 2 },
      768: { slidesPerView: 4 },
    },
  }),
};
</script>

<style scoped lang="scss">
:deep(.swiper-primary) {
  .caption {
    height: 50px;
    display: grid;
    place-items: center;
    max-width: 200px;
  }
  .swiper-pagination-bullet-active {
    background: black;
  }
}
</style>
